import React from "react"
import { Parallax } from 'react-scroll-parallax'

import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

const CreateLinks = () => {
  const data = useStaticQuery(graphql`
    query WatchImageQuery {
      file(relativePath: {eq: "content_Watch.png"}) {
        childImageSharp {
          fluid {
            base64
            src
            aspectRatio
          }
        }
      }
    }
  `)

  return (
    <div className="page">
      <div className="page__content">
        <Parallax className="content__column-image" y={[0, -10]} x={[0, -10]}>
          <Img
            fluid={data.file.childImageSharp.fluid}
            alt="Crie Links para pagamentos em segundos" />
        </Parallax>

        <Parallax className="content__column-text" y={[0, 20]}>
            <h2>Crie links para pagamentos<br /> em segundos</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
        </Parallax>
      </div>
    </div>
  )
}

export default CreateLinks
